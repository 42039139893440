import * as React from "react"
import Main from './index.js'
// markup
const NotFoundPage = () => {
  return (
    <Main/>
  )
}

export default NotFoundPage
